<template>
  <section class="cart-manager">
    <article class="cart-page page">
      <button class="close-cart-button" @click="closeCart">
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
      </button>
      <h2>{{ $t("cart.myCart") }}</h2>
      <CartComponent />
      <div class="page__checkout-buttons">
        <button class="button-white" @click="closeCart">
          {{ $t("button.keepShopping") }}
        </button>
        <button class="button-black" @click="onCheckout">
          {{ $t("button.checkout") }}
        </button>
      </div>
    </article>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CartComponent from "./CartComponent.vue";

export default {
  name: "CartManager",
  components: {
    CartComponent,
  },
  computed: {
    ...mapGetters("brushItems", ["getBrushList", "getBrushById"]),
    ...mapGetters("procreateItems", ["getSetItemsList"]),
    ...mapGetters("travelItems", ["getTravelKitItemsList"]),
    ...mapGetters("currencyList", [
      "getCurrencyTitle",
      "getCurrentPrice",
      "getCurrencyRate",
    ]),
    ...mapGetters("cartList", ["getCartList", "getItemById"]),
  },

  methods: {
    ...mapActions("cartList", ["loadCartList", "rejectItem"]),
    onCheckout() {
      this.$router.push({
        name: "payment",
      });
    },
    closeCart() {
      this.$emit("close-cart");
    },
    getItemImage(itemId) {
      const flavor = this.getFlavorsList.find((item) => item.id == itemId);

      if (flavor) {
        return flavor.image;
      } else {
        return;
        //   require("");
      }
    },
    getItemTitle(itemId) {
      const flavor = this.getFlavorsList.find((item) => item.id == itemId);

      if (flavor) {
        return flavor.title;
      } else {
        return "";
      }
    },
    getItemPrice(itemId) {
      const flavor = this.getFlavorsList.find((item) => item.id == itemId);

      if (flavor) {
        return flavor.price;
      } else {
        return "";
      }
    },
    getItemPriceWithQuantity(itemId) {
      const cartItem = this.getItemById(itemId);
      return this.getItemPrice(itemId) * cartItem.quantity;
    },

    getTotalPrice() {
      return this.getCartList.reduce((totalPrice, item) => {
        const price = this.getItemPriceWithQuantity(item.id);

        return totalPrice + price;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 24px;
}
</style>
